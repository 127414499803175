var exports = {};
exports = asString;
exports.add = append;

function asString(fonts) {
  var href = getHref(fonts);
  return "<link href=\"" + href + "\" rel=\"stylesheet\" type=\"text/css\">";
}

function asElement(fonts) {
  var href = getHref(fonts);
  var link = document.createElement("link");
  link.setAttribute("href", href);
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  return link;
}

function getHref(fonts) {
  var family = Object.keys(fonts).map(function (name) {
    var details = fonts[name];
    name = name.replace(/\s+/g, "+");
    return typeof details === "boolean" ? name : name + ":" + makeArray(details).join(",");
  }).join("|");
  return "//fonts.googleapis.com/css?family=" + family;
}

function append(fonts) {
  var link = asElement(fonts);
  document.head.appendChild(link);
  return link;
}

function makeArray(arr) {
  return Array.isArray(arr) ? arr : [arr];
}

export default exports;
export const add = exports.add;